/* .TVChartContainer {
	height: calc(100vh - 80px);
} */

/* .TVChartContainer {
	height: 100%; 
	width: 100%; 
	min-width: auto;
  } */


.TVChartContainer {
  /* display: none; */
  /* Hide by default */
  height: 100%;
  /* Full height of its container */
  width: 100%;
  /* Full width of its container */
  min-width: auto;
}

/* Show the chart only on screens larger than 800px wide */
@media screen and (min-width: 1001px) {
  .TVChartContainer {
    /* display: block; */
  }
}